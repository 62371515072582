#home {
    width: 100%;
    .container {
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .swap-container {
        max-width: 1030px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 5rem;

        .tabs-container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;

            .tab {
                border: none;
                outline: none;
                color: $accentColor;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 0.5rem;
                transition: border-color 0.7s ease;
                // cursor: pointer;
                height: 56px;
                border-radius: 10px;
                opacity: 0.9;

                &:focus,
                &:hover {
                    color: grey;
                    border-bottom-color: grey;

                    svg path {
                        fill: grey;
                    }
                }

                svg path {
                    transition: fill 0.7s ease;
                }

                h3 {
                    margin-left: 1rem;
                    font-family: $primaryFont;
                    transition: color 0.7s ease;
                    font-size: 36px;
                }

                &.active {
                    background: $bgSecondary;
                    border-bottom-color: $primaryColor;

                    svg {
                        path {
                            fill: $accentColor;
                        }
                    }

                    h3 {
                        color: $accentColor;
                    }
                }

                &:not(.active) {
                    background-color: $bgPrimary;
                }
            }
        }

        .tabs-content {
            color: #d2c7de;
            margin: 0.8rem 0;
            display: grid;
            grid-template-columns: 1fr 25%;
            gap: 1rem;

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
            }

            .inner__left {
                background-color: $bgPrimary;
                padding: 3.75rem;
                display: grid;
                grid-template-columns: 1fr max-content 1fr;
                gap: 1.68rem;
                border-radius: 10px;
                opacity: 0.9;

                @include breakpoint(tablet) {
                    padding: 1.68rem;
                }

                .token__1 {
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }

                svg {
                    justify-self: center;
                    @include breakpoint(phablet) {
                        max-width: 70%;
                    }

                    path {
                        fill: $accentColor;
                    }
                }

                h3 {
                    padding-bottom: 1rem;
                    width: 80%;
                    border-bottom: 2px solid #42266a;
                    margin-bottom: 1rem;
                    font-family: $primaryFont;
                    font-size: 36px;
                    color: $accentColor;

                    @include breakpoint(phablet) {
                        font-size: 28px;
                    }

                    @include breakpoint(mobile) {
                        font-size: 24px;
                    }
                }

                p {
                    margin-bottom: 0.3rem;
                }

                span {
                    color: $primaryColor;
                    font-weight: 700;
                }
            }

            .inner__right {
                background-color: #3a1451;
                position: relative;
                padding: 3.75rem 3.75rem 3.75rem 3rem;
                border-radius: 10px;
                opacity: 0.9;

                @include breakpoint(laptop) {
                    padding: 2.5rem;
                }

                @include breakpoint(tablet) {
                    padding: 1.68rem;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 3.75rem;
                }

                @include breakpoint(mobile) {
                    grid-template-columns: 1fr;
                    gap: 1.68rem;
                }

                &:before {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    top: 3.75rem;
                    left: -10px;
                    width: 20px;
                    height: 20px;
                    transform: rotate(45deg);
                    background-color: $bgSecondary;

                    @include breakpoint(tablet) {
                        top: -10px;
                        left: 50%;
                        transform: translate(-50%) rotate(45deg);
                    }
                }

                .lp-tokens {
                    margin-bottom: 1.68rem;

                    @include breakpoint(tablet) {
                        margin-bottom: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        text-align: right;
                    }

                    @include breakpoint(mobile) {
                        align-items: flex-start;
                        text-align: left;
                    }
                }

                p {
                    margin-bottom: 0.3rem;
                }

                span {
                    font-weight: 700;
                    color: $primaryColor;
                    display: block;
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    background: $bgSecondary;
                    color: $accentColor;
                    border: none;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 18px;
                    height: 40px;
                    border-radius: 100px;
                    margin-top: 0.5rem;

                    @include breakpoint(tablet) {
                        width: 50%;
                    }
                }
            }
        }

        .rewards-container {
            padding: 1.68rem 0;
            margin-bottom: 0.8rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            color: #d2c7de;

            @include breakpoint(laptop) {
                grid-template-columns: minmax(0, 1fr);
            }

            .estimated-rewards-container {
                display: grid;
                grid-template-rows: 1fr 1fr;
                align-items: center;
                width: 100%;
                @include breakpoint(laptop) {
                    margin-bottom: 2.5rem;
                }

                @include breakpoint(mobile) {
                    margin-bottom: 1.68rem;
                }

                .estimate-rewards {
                    p {
                        @include breakpoint(desktop) {
                            display: flex;
                            flex-direction: column;
                        }

                        @include breakpoint(laptop) {
                            flex-direction: row;
                        }

                        @include breakpoint(mobile) {
                            flex-direction: column;
                            font-size: 14px;
                        }

                        span {
                            &:not(.amount) {
                                margin-right: 0.2rem;
                                display: inline-block;
                            }
                            &.amount {
                                color: $primaryColor;
                                font-weight: 700;
                            }
                        }
                    }

                    & + .estimate-rewards {
                        margin-top: 1rem;
                    }
                }
            }

            .actions-container {
                width: 100%;

                .claim-container,
                .withdraw-container {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    p {
                        flex-grow: 1;
                        background-color: $bgSecondary;
                        padding: 0.8rem 1rem;
                        font-size: 16px;
                        display: flex;

                        @include breakpoint(laptop) {
                            padding: 0;
                        }

                        @include breakpoint(mobile) {
                            font-size: 14px;
                        }

                        span {
                            color: $primaryColor;
                            font-weight: 700;
                            display: flex;

                            &.amount {
                                margin: 0 5px;
                                display: inline-block;
                                width: 80%;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;

                                @include breakpoint(laptop) {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .button-container {
                        width: 250px;
                        cursor: pointer;

                        @include breakpoint(phablet) {
                            width: 200px;
                            font-size: 18px;
                        }

                        @include breakpoint(mobile) {
                            width: 100px;
                            font-size: 12px;
                        }

                        button {
                            cursor: pointer;
                            width: 100%;
                        }
                    }
                }

                .withdraw-container {
                    margin-top: 1rem;
                }
            }
        }

        .links-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.8rem;

            &.add-only {
                grid-template-columns: 1fr;

                .button-container {
                    display: none;
                }

                a {
                    border-radius: 100px;
                }
            }

            a {
                &.liquidity:not(.remove) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    background: $bgSecondary;
                    color: $accentColor;
                    border: none;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 20px;
                    height: 60px;
                    border-radius: 100px;

                    @include breakpoint(tablet) {
                        height: 50px;
                        font-size: 18px;
                    }

                    @include breakpoint(mobile) {
                        height: 40px;
                        font-size: 14px;
                    }
                }

                &.remove {
                    background: transparent;
                }
            }
        }
    }
}
