footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 3.75rem;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.75rem 0;

        @include breakpoint(phablet) {
            flex-direction: column;
        }

        .copyrights {
            font-weight: 500;
            text-align: center;
            font-size: 18px;

            span {
                color: $accentColor;

                &.app-name {
                    color: #cc3180;
                }
            }

            .powered-by {
                text-align: left;

                @include breakpoint(phablet) {
                    text-align: center;
                }

                a {
                    text-decoration: underline;
                }
            }
        }

        .social-media-container {
            display: flex;
            align-items: center;

            @include breakpoint(phablet) {
                order: -1;
                margin-bottom: 2rem;
            }

            a {
                margin: 0 0.8rem;
            }
        }
    }
}
