#remove-liquidity {
    padding-bottom: 5rem;

    .container {
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            padding: 0;
        }

        .liquidity-form {
            width: 650px;
            background: $bgPrimary;
            z-index: 1000;
            height: max-content;
            border-radius: 10px;
            overflow: hidden;

            @include breakpoint(phablet) {
                width: 100%;
                max-width: 650px;
            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.68rem 3.75rem 0 3.75rem;

                @include breakpoint(phablet) {
                    padding: 1.68rem 1.68rem 0 1.68rem;
                }

                h2 {
                    font-size: 36px;
                    font-family: $primaryFont;
                    @include gradientText;

                    @include breakpoint(phablet) {
                        font-size: 28px;
                    }

                    @include breakpoint(mobile) {
                        font-size: 24px;
                    }
                }

                svg {
                    width: 23px;
                    height: 23px;
                    @include breakpoint(phablet) {
                        width: 20px;
                    }
                }
            }

            .content {
                .amount-container {
                    padding: 1.68rem 3.75rem;

                    @include breakpoint(phablet) {
                        padding: 1.68rem 1.68rem 2rem 1.68rem;
                    }

                    .token-amount-container {
                        width: 100%;

                        .token-balance {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            h3 {
                                color: $accentColor;
                                font-family: $primaryFont;
                                font-weight: 600;
                                font-size: 36px;

                                @include breakpoint(phablet) {
                                    font-size: 28px;
                                }

                                @include breakpoint(mobile) {
                                    font-size: 22px;
                                }
                            }

                            .balance {
                                color: $accentColor;
                                font-size: 18px;

                                @include breakpoint(mobile) {
                                    font-size: 14px;
                                }

                                span {
                                    font-weight: 700;
                                    color: $primaryColor;
                                }
                            }
                        }

                        .input-container {
                            margin-top: 0.5rem;
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) 108px;
                            gap: 1rem;

                            @include breakpoint(mobile) {
                                grid-template-columns: minmax(0, 1fr) 80px;
                            }

                            input {
                                width: 100%;
                                font-size: 18px;
                                @include inputStyles;

                                &::-webkit-outer-spin-button,
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    -moz-appearance: textfield;
                                }
                            }

                            button {
                                font-size: 18px;
                                font-weight: 500;
                                @include buttonStyles;
                            }
                        }
                    }
                }

                .pooled-token-container {
                    background-color: #1a082d;
                    padding: 1.68rem 5rem;

                    @include breakpoint(mobile) {
                        padding: 1.68rem;
                    }
                    .row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 700;

                        & + .row {
                            margin-top: 1.68rem;
                        }

                        .token-amount {
                            color: $primaryColor;
                        }

                        .token-name {
                            font-size: 24px;
                            color: $accentColor;
                        }
                    }
                }

                .liquidity-buttons-container {
                    background-color: #1a082d;
                    padding: 0 3.75em 2.5rem 3.75rem;

                    @include breakpoint(phablet) {
                        padding: 0 1.68rem 2rem 1.68rem;
                    }

                    .liquidity-button-row {
                        display: grid;
                        grid-template-columns: 60px minmax(0, 1fr);
                        gap: 1rem;

                        &.single {
                            grid-template-columns: minmax(0, 1fr);
                        }

                        @include breakpoint(phablet) {
                            grid-template-columns: 40px minmax(0, 1fr);
                        }

                        & + .liquidity-button-row {
                            margin-top: 1rem;
                        }

                        .step {
                            width: 60px;
                            height: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 18px;
                            font-weight: 500;
                            background-color: #37134d;
                            border-radius: 50%;

                            @include breakpoint(phablet) {
                                width: 40px;
                                height: 40px;
                                margin-right: 1rem;
                            }
                        }

                        button {
                            flex-grow: 1;
                            font-size: 18px;
                            line-height: 27px;
                            font-weight: 500;
                            @include buttonStyles;

                            @include breakpoint(phablet) {
                                height: 40px;
                                font-size: 16px;
                                padding: 0.5rem;
                            }

                            &:disabled {
                                pointer-events: none;
                                background: #3a1451;
                                color: rgba(255, 255, 255, 0.5);
                            }

                            @include breakpoint(phablet) {
                                padding: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
