$primaryColor: var(--primaryColor);
$accentColor: var(--accentColor);
$bgPrimary: var(--bgPrimary);
$bgSecondary: var(--bgSecondary);
$primaryFont: 'Poppins', 'Open Sans';

@mixin gradientText {
    background: -webkit-linear-gradient(
        left,
        rgba(104, 55, 218, 1),
        rgba(227, 53, 134, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin inputStyles {
    height: 60px;
    border-radius: 100px;
    background: transparent;
    border: 1px solid #42266a;
    outline: none;
    padding: 1rem;
    color: $accentColor;

    &::placeholder {
        opacity: 1;
    }
}

@mixin buttonStyles {
    border-radius: 100px;
    background: linear-gradient(107.15deg, #6237dd 0%, #e93580 100%);
    color: $accentColor;
    height: 60px;
    width: 100%;
    cursor: pointer;
    border: none;
}

@mixin breakpoint($point) {
    @if $point == desktop {
        @media (max-width: 1440px) {
            @content;
        }
    } @else if $point == laptop {
        @media (max-width: 1120px) {
            @content;
        }
    } @else if $point == tablet {
        @media (max-width: 900px) {
            @content;
        }
    } @else if $point == phablet {
        @media (max-width: 768px) {
            @content;
        }
    } @else if $point == mobile {
        @media (max-width: 500px) {
            @content;
        }
    } @else {
        @media ($point) {
            @content;
        }
    }
}

@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-weight: 400;
    src: local('Poppins-Regular'),
        url(../assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-weight: 600;
    src: local('Poppins-SemiBold'),
        url(../assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}

body {
    background-color: #0d0415;
    color: $accentColor;
    font-family: $primaryFont;
}

#root {
    min-height: 100vh;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr max-content;
    overflow: hidden;
}

main {
    margin-top: 10rem;
    position: relative;
    display: flex;
    justify-content: center;

    @include breakpoint(tablet) {
        margin-top: 10rem;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

section {
    display: flex;
    justify-content: center;
}

.container {
    max-width: 1440px;
    width: 100%;
    padding: 0 3.75rem;
    position: relative;

    @include breakpoint(phablet) {
        padding: 0 1.68rem;
    }
}

p {
    font-size: 18px;
}

// reusable
.button-container {
    position: relative;
    overflow: hidden;
    color: #fff;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 20px;
    font-weight: 600;

    @include breakpoint(phablet) {
        height: 50px;
        font-size: 18px;
    }

    @include breakpoint(mobile) {
        height: 40px;
        font-size: 14px;
    }

    & > * {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 30px;
        padding: 2px; /* the thickness of the border */
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0) padding-box;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0) padding-box;
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 400px;
            height: 400px;
            background: linear-gradient(
                to right,
                rgba(98, 55, 221, 1) 0%,
                rgba(224, 53, 135, 1) 100%
            );
            transform: translate(-50%, -50%) rotate(45deg);
            transition: transform 0.7s linear;
        }
    }
}
