header {
    position: fixed;
    display: flex;
    z-index: 99999;
    justify-content: center;
    width: 100%;
    transition: background-color 1s ease;

    &.sticky {
        background-color: $bgPrimary;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inner__left {
            padding: 1.68rem 0;
            a {
                display: flex;
                align-items: center;

                svg {
                    @include breakpoint(phablet) {
                        max-width: 80%;
                    }
                }

                h1 {
                    font-family: $primaryFont;
                    text-transform: lowercase;
                    text-transform: uppercase;
                    font-size: 55px;
                    margin-left: 1rem;

                    @include breakpoint(phablet) {
                        display: none;
                    }
                }
            }
        }

        .inner__right {
            display: flex;
            align-items: center;

            .uniswap-container {
                margin-right: 3rem;

                @include breakpoint(phablet) {
                    margin-right: 1rem;
                }

                svg {
                    cursor: pointer;

                    transition: transform 0.3s ease;

                    .st0 {
                        fill: white;
                    }
                    .st1 {
                        fill-rule: evenodd;
                        clip-rule: evenodd;
                        fill: white;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .wallet-container {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 25px;
                font-weight: 500;
                cursor: pointer;
                background-color: transparent;
                border: none;
                color: $accentColor;
                transform: scale(1);
                transition: transform 0.3s ease;

                &:hover {
                    outline: none;
                    transform: scale(1.1);
                }

                svg {
                    path {
                        fill: white;
                    }
                }

                & > span {
                    margin-left: 1rem;
                    @include breakpoint(phablet) {
                        font-size: 18px;
                        margin-left: 0.5rem;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
