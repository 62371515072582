.modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 1.68rem;

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        background: transparent;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        border-radius: 10px;
        outline: none;
        text-align: center;

        &.connect-wallet {
            max-width: 650px;
            background: $bgPrimary;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.68rem 3.75rem;

                h2 {
                    font-size: 36px;
                    line-height: 54px;
                    @include gradientText;
                }

                .cross-container {
                    cursor: pointer;

                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }
            }

            .content {
                color: $primaryColor;
                padding: 1.68rem 3.75rem;

                .wallet-connector {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: background-color 1s ease;
                    position: relative;
                    background-color: #3a1451;
                    border-radius: 100px;
                    height: 60px;
                    color: $accentColor;
                    cursor: pointer;

                    & + .wallet-connector {
                        margin-top: 1.68rem;
                    }

                    svg {
                        position: absolute;
                        left: 1.68rem;
                    }

                    h3 {
                        padding: 1rem;
                        transition: color 0.5s ease;
                    }

                    &:hover,
                    &.active {
                        color: $accentColor;
                        background: $bgSecondary;
                    }
                }
            }
        }

        &.prompt {
            max-width: 650px;
            background: $bgSecondary;
            padding: 2.5rem 5rem;

            @include breakpoint(mobile) {
                padding: 2rem;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    font-size: 36px;

                    @include breakpoint(mobile) {
                        font-size: 32px;
                    }
                }

                .cross-container {
                    cursor: pointer;

                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }
            }

            .content {
                color: $accentColor;
                margin-top: 1.68rem;
                text-align: left;

                .buttons-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 1.68rem;
                    margin-top: 1.68rem;

                    button {
                        padding: 1rem;
                        font-weight: 500;
                        font-size: 18px;
                        cursor: pointer;
                        border-radius: 100px;
                        outline: none;
                        border: none;

                        &.cancel {
                            background: #3a1451;
                            color: $accentColor;
                        }

                        &.confirm {
                            background: $accentColor;
                            color: #e63583;
                        }
                    }
                }
            }
        }

        &.confirmation,
        &.transaction-success {
            max-width: 650px;
            background: $bgSecondary;

            .header {
                display: flex;
                justify-content: flex-end;
                padding: 1.68rem;

                .cross-container {
                    cursor: pointer;

                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }
            }

            .content {
                padding: 0 1.68rem 3.68rem 1.68rem;
                color: $accentColor;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .loading-container {
                    svg {
                        width: 120px;
                        animation: rotate 1s linear infinite;

                        circle {
                            stroke-width: 5;
                            stroke: $accentColor;
                        }
                    }
                }

                p {
                    font-size: 22px;
                }
            }
        }

        &.transaction-success {
            .content {
                .uploaded-container {
                    margin-bottom: 3rem;
                    svg {
                        width: 120px;
                        height: auto;
                        stroke: $accentColor;
                    }
                }

                a {
                    color: $accentColor;
                    margin-top: 1rem;
                }
            }
        }

        &.warning {
            max-width: 650px;
            background: $bgSecondary;

            .content {
                padding: 3.68rem 3.68rem 1rem 3.68rem;
                color: $accentColor;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    font-size: 22px;
                }

                button {
                    margin-top: 3.68rem;
                    width: 100%;
                    border: none;
                    background-color: #3a1451;
                    border-radius: 100px;
                    color: $accentColor;
                    font-size: 18px;
                    line-height: 27px;
                    cursor: pointer;
                    padding: 1rem;
                }
            }
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
