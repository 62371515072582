#countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;

    .app-background {
        z-index: -1;
    }

    h1 {
        font-family: $primaryFont;
        margin-top: 8rem;
        font-size: 122px;
        color: $primaryColor;
        text-align: center;
        line-height: 80px;

        @include breakpoint(phablet) {
            font-size: 100px;
        }
    }

    h2 {
        margin-top: 3rem;
        font-size: 38px;
        letter-spacing: 10px;
        font-family: $primaryFont;
        font-weight: 500;
        word-spacing: 10px;
        text-transform: uppercase;
        text-align: center;

        @include breakpoint(laptop) {
            font-size: 30px;
        }

        @include breakpoint(phablet) {
            margin-top: 2rem;
            font-size: 25px;
        }

        @include breakpoint(mobile) {
            font-size: 20px;
        }
    }

    h3 {
        font-size: 22px;
        margin-top: 1rem;
        text-transform: capitalize;

        @include breakpoint(laptop) {
            font-size: 18px;
        }

        @include breakpoint(phablet) {
            font-size: 16px;
        }

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    .timer-container {
        display: flex;
        position: relative;

        .time-container {
            margin: 2rem 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include breakpoint(tablet) {
                margin: 2rem;
            }

            @include breakpoint(phablet) {
                margin: 2rem 1.5rem;
            }

            @include breakpoint(mobile) {
                margin: 2rem 0.5rem;
            }

            .time {
                font-size: 150px;
                font-family: $primaryFont;

                @include breakpoint(laptop) {
                    font-size: 120px;
                }

                @include breakpoint(tablet) {
                    font-size: 90px;
                }

                @include breakpoint(phablet) {
                    font-size: 70px;
                }

                @include breakpoint(mobile) {
                    font-size: 55px;
                }
            }

            .label {
                text-align: center;
                color: $primaryColor;
                font-weight: 700;
                font-size: 30px;
                letter-spacing: 2px;
                text-transform: uppercase;

                @include breakpoint(laptop) {
                    font-size: 22px;
                }

                @include breakpoint(tablet) {
                    font-size: 18px;
                }

                @include breakpoint(phablet) {
                    font-size: 16px;
                }

                @include breakpoint(mobile) {
                    font-size: 12px;
                }
            }
        }
    }
}
