.app-background {
    position: fixed;
    width: 100%;
    height: 100%;

    &::before {
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background: radial-gradient(
            100.7% 157.18% at 81.67% 23.05%,
            #30175b 0%,
            rgba(21, 10, 36, 0.36) 100%
        );
        opacity: 0.4;
    }

    #fluid {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;

        @include breakpoint(phablet) {
            display: none;
        }
    }

    #small-meteor-top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);

        @include breakpoint(phablet) {
            display: none;
        }
    }

    #meteor-big {
        position: absolute;
        top: 25%;

        @include breakpoint(desktop) {
            left: -5%;
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    #meteor-small {
        position: absolute;
        top: 70%;

        @include breakpoint(tablet) {
            top: 50%;
        }
    }

    #planet {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        @include breakpoint(tablet) {
            width: 150px;
        }
    }

    #red-meteor {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, 200%);

        @include breakpoint(tablet) {
            width: 70px;
            transform: translate(-50%, 120%);
        }
    }

    #shooting-star {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;

        @include breakpoint(desktop) {
            left: 20%;
        }

        @include breakpoint(tablet) {
            width: 100%;
            left: 40%;
        }

        @include breakpoint(phablet) {
            opacity: 0.7;
        }
    }
}
