#liquidity {
    padding-bottom: 5rem;

    .container {
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            padding: 0;
        }

        .liquidity-form {
            width: 650px;
            border-radius: 10px;
            overflow: hidden;
            background: $bgPrimary;
            z-index: 1000;
            height: max-content;

            @include breakpoint(phablet) {
                width: 100%;
                max-width: 650px;
            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.68rem 3.75rem 0 3.75rem;

                @include breakpoint(phablet) {
                    padding: 1.68rem 1.68rem 0 1.68rem;
                }

                h2 {
                    font-size: 36px;
                    font-family: $primaryFont;
                    @include gradientText;
                    @include breakpoint(phablet) {
                        font-size: 28px;
                    }

                    @include breakpoint(mobile) {
                        font-size: 24px;
                    }
                }

                svg {
                    width: 23px;
                    height: 23px;
                    @include breakpoint(phablet) {
                        width: 20px;
                    }
                }
            }

            .content {
                margin-top: 1.68rem;

                @include breakpoint(phablet) {
                    margin-top: unset;
                }

                .amount-container {
                    padding: 1.68rem 3.75rem 2.5rem 3.75rem;

                    @include breakpoint(phablet) {
                        padding: 1.68rem 1.68rem 2rem 1.68rem;
                    }

                    .token-amount-container {
                        width: 100%;
                        & + .token-amount-container {
                            margin-top: 1.68rem;
                        }

                        .token-balance {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            h3 {
                                color: $accentColor;
                                font-family: $primaryFont;
                                font-size: 36px;

                                @include breakpoint(phablet) {
                                    font-size: 28px;
                                }

                                @include breakpoint(mobile) {
                                    font-size: 22px;
                                }
                            }

                            .balance {
                                color: $accentColor;
                                font-size: 18px;

                                @include breakpoint(mobile) {
                                    font-size: 14px;
                                }

                                span {
                                    font-weight: 700;
                                    color: $primaryColor;
                                }
                            }
                        }

                        .input-container {
                            margin-top: 0.5rem;
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) 108px;
                            gap: 1rem;

                            @include breakpoint(mobile) {
                                grid-template-columns: minmax(0, 1fr) 80px;
                            }

                            input {
                                width: 100%;
                                font-size: 18px;
                                @include inputStyles;

                                &::-webkit-outer-spin-button,
                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    -moz-appearance: textfield;
                                }
                            }

                            button {
                                font-size: 18px;
                                font-weight: 500;
                                @include buttonStyles;
                            }
                        }
                    }
                }

                .liquidity-container {
                    background-color: #1a082d;
                    padding: 1.68rem;
                    display: grid;
                    grid-template-columns: 1fr max-content 1fr;
                    width: 100%;
                    gap: 1.68rem;

                    @include breakpoint(mobile) {
                        gap: 0.5rem;
                    }

                    p {
                        color: $accentColor;
                        line-height: 27px;

                        @include breakpoint(mobile) {
                            font-size: 16px;
                        }
                    }

                    span {
                        color: $primaryColor;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 27px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                        display: block;

                        @include breakpoint(mobile) {
                            font-size: 14px;
                        }
                    }

                    .inner__left {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        overflow: hidden;

                        p,
                        span {
                            text-align: right;
                        }
                    }

                    .inner__right {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        overflow: hidden;
                    }

                    svg {
                        justify-self: center;

                        path {
                            fill: $accentColor;
                        }

                        @include breakpoint(phablet) {
                            max-width: 70%;
                        }
                    }
                }

                .liquidity-buttons-container {
                    padding: 0 3.75em 2.5rem 3.75rem;
                    background-color: #1a082d;

                    @include breakpoint(phablet) {
                        padding: 0 1.68rem 2rem 1.68rem;
                    }

                    .liquidity-button-row {
                        display: grid;
                        grid-template-columns: 60px minmax(0, 1fr);
                        gap: 1rem;

                        @include breakpoint(phablet) {
                            grid-template-columns: 40px minmax(0, 1fr);
                        }

                        & + .liquidity-button-row {
                            margin-top: 1rem;
                        }

                        .step {
                            width: 60px;
                            height: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 18px;
                            font-weight: 500;
                            background-color: #37134d;
                            border-radius: 50%;

                            @include breakpoint(phablet) {
                                width: 40px;
                                height: 40px;
                                margin-right: 1rem;
                            }
                        }

                        button {
                            flex-grow: 1;
                            font-size: 18px;
                            line-height: 27px;
                            font-weight: 500;
                            @include buttonStyles;

                            @include breakpoint(phablet) {
                                height: 40px;
                                font-size: 16px;
                                padding: 0.5rem;
                            }

                            &.insufficient-balance,
                            &.connect-wallet,
                            &.enter-an-amount {
                                grid-column: 1/3;
                            }

                            &:disabled {
                                pointer-events: none;
                                background: #3a1451;
                                color: rgba(255, 255, 255, 0.5);
                            }
                        }
                    }
                }
            }
        }
    }
}
