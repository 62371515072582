#stake {
    .container {
        display: flex;
        justify-content: center;
    }

    @include breakpoint(tablet) {
        padding: 0;
    }

    .stake-form {
        width: 650px;
        background-color: $bgPrimary;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1000;
        height: max-content;

        @include breakpoint(phablet) {
            width: 100%;
            max-width: 650px;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.68rem 3.75rem 0 3.75rem;

            @include breakpoint(phablet) {
                padding: 1.68rem 1.68rem 0.5rem 1.68rem;
            }

            h2 {
                font-size: 36px;
                font-family: $primaryFont;
                @include gradientText;

                @include breakpoint(phablet) {
                    font-size: 28px;
                }

                @include breakpoint(mobile) {
                    font-size: 24px;
                }
            }

            svg {
                width: 23px;
                height: 23px;
                @include breakpoint(phablet) {
                    width: 20px;
                }
            }
        }

        .content {
            & > * {
                padding: 0 3.75rem;

                @include breakpoint(phablet) {
                    padding: 0 1.68rem;
                }
            }

            .lp-balance-container {
                margin-bottom: 1rem;
                p {
                    color: $accentColor;

                    @include breakpoint(mobile) {
                        font-size: 14px;
                    }

                    span {
                        color: $primaryColor;
                        font-weight: 700;
                    }
                }
            }

            .input-container {
                display: grid;
                grid-template-columns: 1fr max-content;
                gap: 1rem;

                input {
                    width: 100%;
                    font-size: 18px;
                    @include inputStyles;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        -moz-appearance: textfield;
                    }
                }

                button {
                    font-size: 18px;
                    font-weight: 500;
                    min-width: 108px;
                    @include buttonStyles;

                    @include breakpoint(mobile) {
                        min-width: 80px;
                    }
                }
            }

            .stake-buttons-container {
                background: #1a082d;
                margin-top: 1.68rem;
                padding: 1.68rem 3.75rem;

                @include breakpoint(phablet) {
                    padding: 1.68rem;
                }

                .stake-button-row {
                    display: grid;
                    grid-template-columns: 60px minmax(0, 1fr);
                    gap: 1rem;

                    @include breakpoint(phablet) {
                        grid-template-columns: 40px minmax(0, 1fr);
                    }

                    &.single {
                        grid-template-columns: minmax(0, 1fr);
                    }

                    & + .stake-button-row {
                        margin-top: 1rem;
                    }

                    .step {
                        width: 60px;
                        height: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 500;
                        background-color: #37134d;
                        border-radius: 50%;

                        @include breakpoint(phablet) {
                            width: 40px;
                            height: 40px;
                            margin-right: 1rem;
                        }
                    }

                    button {
                        flex-grow: 1;
                        font-size: 18px;
                        line-height: 27px;
                        font-weight: 500;
                        @include buttonStyles;

                        @include breakpoint(phablet) {
                            height: 40px;
                        }

                        &:disabled {
                            pointer-events: none;
                            background: #3a1451;
                            color: rgba(255, 255, 255, 0.5);
                        }

                        @include breakpoint(phablet) {
                            padding: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
